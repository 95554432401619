import cn from "classnames";
import { useState } from "react";
import { Button, Container, Navbar, Offcanvas, Stack } from "react-bootstrap";

import { MainMenu } from "./components/MainMenu";
import { MenuFooter } from "./components/MenuFooter";
import { TopMenu } from "./components/TopMenu";

import classes from "./styles.module.scss";

const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME;

export default function ({ className, theme = "light" }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Navbar className={cn(className, classes.Header)} data-bs-theme={theme}>
        <Container fluid="md">
          <Navbar.Brand href="/" aria-label={SITE_NAME}>
            <img src="/assets/Logo.svg" width="136" height="50" alt="" />
          </Navbar.Brand>
          <Stack className="flex-row align-items-center justify-content-end">
            <TopMenu className="d-none d-md-flex pe-6 me-4" />
            <Button variant="outline-cyan" className={classes.toggle} onClick={() => setShow(true)}>
              <img src="/assets/Bars.svg" alt="" />
              <span>Menu</span>
            </Button>
          </Stack>
        </Container>
        <Offcanvas
          className="bg-blue text-white"
          show={show}
          onHide={() => setShow(false)}
          placement="end"
        >
          <Offcanvas.Header className="justify-content-end pt-4 px-5">
            <Button
              variant="cyan"
              className="d-flex align-items-center"
              onClick={() => setShow(false)}
            >
              <img src="/assets/close.svg" width="14" height="14" alt="Close" />
              <span className="fw-bold ms-1">Close</span>
            </Button>
          </Offcanvas.Header>
          <Offcanvas.Body className="d-flex flex-column pt-0 py-md-5 px-md-4">
            <MainMenu className="flex-grow-1 mx-md-4 pe-1 px-md-4" />
            <MenuFooter className="flex-grow-0 mx-md-4 mt-4 mt-md-5 px-md-4" />
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
    </>
  );
}

import useTopMenu from "@/hooks/useTopMenu";
import cn from "classnames";
import { Dropdown, Nav } from "react-bootstrap";

import classes from "./styles.module.scss";

export const TopMenu = ({ className }) => {
  const menu = useTopMenu();

  return (
    <Nav className={cn(className, classes.TopMenu)}>
      {menu.map(({ title, href, children }, index) => {
        if (children.length === 0) {
          return (
            <Nav.Item key={index}>
              <Nav.Link href={href} target={href.startsWith("http") ? "_blank" : "_self"}>
                {title}
              </Nav.Link>
            </Nav.Item>
          );
        }

        return (
          <Dropdown as={Nav.Item} key={index}>
            <Dropdown.Toggle as={Nav.Link} className="text-l">
              {title}
            </Dropdown.Toggle>
            <Dropdown.Menu data-bs-theme="light" className="shadow-sm">
              {children.map((child, idx) => (
                <Dropdown.Item as={Nav.Item} key={idx}>
                  <Nav.Link
                    href={child.href}
                    target={child.href.startsWith("http") ? "_blank" : "_self"}
                  >
                    {child.title}
                  </Nav.Link>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      })}
    </Nav>
  );
};

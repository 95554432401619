import useGlobal from "@/hooks/useGlobal";
import useSocialLinks from "@/hooks/useSocialLinks";
import { SocialLink } from "@/ui/SocialLink";
import { useSourceFlowText } from "@sourceflow-uk/sourceflowtext";
import cn from "classnames";
import { Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export const MenuFooter = ({ className = "" }) => {
  const global = useGlobal();
  const socials = useSocialLinks();
  const EditableText = useSourceFlowText({ global, prefix: "header.menu.socials", type: "text" });

  return (
    <Stack className={cn(className, classes.MenuFooter)}>
      <div className="d-flex justify-content-center justify-content-sm-start">
        <img src="/assets/LogoWhite.svg" width={149} alt="" />
      </div>
      <Stack className="align-items-center align-items-sm-end gap-2">
        <EditableText path="title" className="small fw-bold">
          Connect with us
        </EditableText>
        <Stack className="flex-row justify-content-center justify-content-sm-end gap-2">
          {socials.map(({ id, href, icon }) => (
            <SocialLink key={id} href={href} icon={icon} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
